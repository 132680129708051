import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { withLastLocation } from 'react-router-last-location';

// import AnimatedWords from '../ui-components/animatedWords';
import AnimatedParagraphLines from '../ui-components/animatedParagraphLines';
import LoadingAnimation from '../ui-components/loadingAnimation';

import getZipData from '../service.js';

import uspsLogo from '../images/usps-logo.png';

const introVideos = {
    loop: '/videos/USPS_EDDM_9x16_04-StoreLoop.mp4',
    rural: '/videos/USPS_EDDM_9x16_05-ZoomRURAL.mp4',
    suburban: '/videos/USPS_EDDM_9x16_05-ZoomSUBURB.mp4',
    urban: '/videos/USPS_EDDM_9x16_05-ZoomCITY.mp4',
};

const videoPosterUrl = '/images/intro-video-bg.jpg';

const errorMessages = {
    numbersOnly: 'ZIP Code must be numbers only.',
    zipLength: 'ZIP Code must be 5 digits.'
};

class Intro extends Component {


    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            formVisible: false,
            loading: false,
            transition: false // flag for when the route is animating out
        };

        this.zipInputRef = React.createRef();

        this.checkErrors = this.checkErrors.bind(this);
    }

    componentDidMount() {

        this.props.setPoster(videoPosterUrl);

        console.log(this.props.lastLocation);


        if (this.props.lastLocation && this.props.lastLocation.pathName === '/') {

            // kick off the looping storefront video, but after 
            setTimeout(() => {
               this.playIntroVideo();
            }, 3000);
        } else {
            this.playIntroVideo();
        }
    }

    playIntroVideo() {
        this.props.triggerVideo(introVideos.loop, { 
            loop: true,
            onPlay: () => {

                console.log('play intro video')
                this.setState({ formVisible: true });
            }
        })
        .then(result => {

            // on video end
        }).catch(e => {
            console.error(e);
            this.setState({ formVisible: true });
        });
    }

    /*
        Check validity of ZIP code
    */
    checkErrors() {

        const zip = this.zipInputRef.current.value;

        let errors = [];

        // basic error checking
        if (!zip.match(/^[0-9]*$/g)) {
            if (errors.indexOf(errorMessages.numbersOnly) === -1) {
                errors.push(errorMessages.numbersOnly);
            }
        }

        this.setState({ zip: zip, errors: errors });

        return errors;
    }

    /*
        ZIP input form submit handler
    */
    zipSubmit(e) {
        e.preventDefault();

        window.scrollTo(0, 0);

        if (this.state.loading) return;

        const zip = this.zipInputRef.current.value;

        let errors = Object.assign([], this.state.errors);
        
        // only check for length on submit - dont show length error before use is done with input!
        if (zip.length < 5) {

            console.log(errors.indexOf(errorMessages.zipLength));

            if (errors.indexOf(errorMessages.zipLength) === -1) {
                errors.push(errorMessages.zipLength);
            }

            console.log(errors);

            this.setState({ zip: zip, errors: errors });
        }
            
        if (errors.length === 0) {

            // check to see if we've already saved it in localStorage (in case user has completed and is doing it again for some reason)

            const zipData = window.localStorage.getItem('zipData'),
                savedData = zipData ? JSON.parse(zipData) : null;

            if (savedData && savedData.zip === zip) {

                console.log('Data for Zip Code already in Local Storage')
                this.goToRouteSelection(savedData);

                // if the zip codes match we can skip the api request, we already have the data
                return;
            }

            this.setState({ loading: true });

            // get route data from API, store in localStorage
            getZipData(zip).then(results => {

                console.log(results);

                if (results.routes.length === 0) {
                    this.setState({
                        loading: false,
                        errors: ['No routes found for this Zip Code™. Please try another.']
                    });
                    return;
                }

                window.localStorage.setItem('zipData', JSON.stringify(results));

                this.goToRouteSelection(results);

            }).catch(e => {
                this.setState({ 
                    loading: false,
                    zip: zip, 
                    errors: ['There was a problem connecting to the server, please try again later.']
                });
            });
        }
    }

    goToRouteSelection(zipData) {

        console.log(zipData);

        let videoUrl;

        switch (zipData.demographicCode) {
            case 'U':
                videoUrl = introVideos.urban;
                break;
            case 'S': 
                videoUrl = introVideos.suburban;
                break;
            case 'R':
                videoUrl = introVideos.rural;
                break;
            default:
                videoUrl = introVideos.suburban;
        }
        console.log('Video URL ', videoUrl);

        this.props.triggerVideo(videoUrl, {
            loop: false,
            onPlay: () => {
                this.setState({
                    transition: true
                });
            }
        }).then(result => {
            console.log('end')
            this.setState({ redirect: true });
        }).catch(e => {
            console.log(e)
            console.error(videoUrl + ' video play failed, proceed to intro');

            this.setState({ redirect: true });
        });

    }

    render() {

        if (this.state.redirect) return <Redirect to={{ pathname: '/route-selection/' + this.state.zip }} />

        const formClassName = this.state.formVisible ? 'visible' : 'hidden';

        let buttonContainerClassName = this.state.loading ? 'form-loading' : '';

        buttonContainerClassName += this.state.buttonActive ? ' active' : '';

        return (
            <div className="route route--intro">

                <Link to="/">
                    <img className="logo" src={uspsLogo} alt="USPS Logo" />
                </Link>

                <div className={'main-content-container ' + (this.state.transition ? 'hide' : '')}>
                    <h1>
                        {/*<AnimatedWords words="Find the right postal routes for your store." />*/}
                        <AnimatedParagraphLines lines={[
                            'Find the right ',
                            'postal routes ',
                            'for your store.'
                        ]} />
                    </h1>

                    <form className={'input-container shadow-container-outer ' + formClassName}>

                        <div className="shadow-container-inner input-with-button" onChange={this.checkErrors} >
                            
                            <div className={'button-container ' + buttonContainerClassName}>
                                <input readOnly={this.state.loading} className="zip-input" type="text" name="zip" maxLength="5" placeholder="Enter business ZIP® Code" ref={this.zipInputRef} />
                                
                                <div 
                                    className="submit-container"
                                    onTouchStart={() => this.setState({ buttonActive: true }) }
                                    onMouseDown={() => this.setState({ buttonActive: true }) }
                                    onTouchEnd={() => this.setState({ buttonActive: false }) }
                                    onMouseUp={() => this.setState({ buttonActive: false }) }>

                                    <input 
                                        className="zip-submit" 
                                        type="submit" 
                                        value="" 
                                        onClick={e => this.zipSubmit(e) }
                                        />
                                </div>
                            </div>

                            <div className="errors">
                                { this.state.errors.map((error, index) => <p className="error" key={index}>{error}</p> ) }
                            </div>
                        </div>

                    </form>


                    { this.state.loading ? 
                        <div className="loading-animation-container">
                            <LoadingAnimation/>
                        </div>
                    : null }
                </div>

            </div>
        );
    }
}

export default withLastLocation(Intro);