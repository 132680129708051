import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

// import AnimatedWords from '../ui-components/animatedWords';
import AnimatedParagraphLines from '../ui-components/animatedParagraphLines';

import uspsLogo from '../images/usps-logo.png';

const homeVideo = '/videos/USPS_EDDM_9x16_00-FullIntro.mp4';

const homeVideoPosterUrl = '/images/home-video-bg.jpg';
    // introVideoPosterUrl = '/images/intro-video-bg.jpg';

export default class FollowUpDiscuss extends Component {


    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            animationIndex: 0,
            animationIntervals: [8000, 5000] // (ms) intervals occur AFTER the index change
        };

        this.startTransition = this.startTransition.bind(this);
        this.animateText = this.animateText.bind(this);
    }

    componentDidMount() {
        this.props.setPoster(homeVideoPosterUrl);

        this.props.triggerVideo(homeVideo, {
            play: false, 
            loop: false,
            onPlay: () => { 
                console.log('play home video'); 
            }
        });
    }

    componentWillUnmount() {
        clearTimeout(this.animationTimeout);
    }

    /*
        Plays video and changes to Intro route
    */
    startTransition() {
        console.log('startTransition')
        this.props.triggerVideo(homeVideo, {
            loop: false,
            onPlay: this.animateText
        }).then(result => {

            // follow redirect when its over
            // this.setState({ redirect: true });
        }).catch(e => {
            console.error(homeVideo + ' video play failed, proceed to intro');
            
            this.setState({ redirect: true });
        });
    }

    /*
        Kicks off the recursive text animation

        This is passed to the video trigger function in App.js so it can be executed on play
    */
    animateText() {

        if (this.state.animationIndex < this.state.animationIntervals.length) {
            
            const nextState = this.state.animationIndex + 1,
                currentInterval = this.state.animationIntervals[this.state.animationIndex];

            this.setState({ animationIndex: nextState });

            this.animationTimeout = setTimeout(() => {
                this.animateText();
            }, currentInterval);
        } else {

            clearTimeout(this.animationTimeout);

            this.setState({ redirect: true });
        }
    }

    render() {

        if (this.state.redirect) return <Redirect to={{ pathname: '/intro' }} />

        return (
            <div className="route route--home">

                <div className={'main-content ' + (this.state.animationIndex > 0 ? 'hide' : '')}>

                    <div className="heading-container shadow-container-outer">
                        <div className="shadow-container-inner">
                            <Link to="/">
                                <img className="logo" src={uspsLogo} alt="USPS Logo" />
                            </Link>
                            <h1>Bring your store to them with EDDM<sup>®</sup> marketing.</h1>
                        </div>
                    </div>

                    <div className="button shadow-container-outer" onClick={this.startTransition}>
                        <div className="shadow-container-inner">TAP TO BEGIN</div>
                    </div>
                </div>

                <div className={'exit-animation-content ' + (this.state.animationIndex > 0 ? 'show' : '')} >
                    
                    { this.state.animationIndex === 2 ? 
                        <h1>

                            {/* <AnimatedWords words="Search customers by age, income, and household size." /> */}

                            <AnimatedParagraphLines lines={[
                                'Search ',
                                'customers by ',
                                'age, income, and ',
                                'household size.'
                            ]} />

                            <span className="animated-underline">
                                <span className="animated-underline-inner" />
                            </span>
                        </h1>
                    : null }
                </div>

            </div>
        );
    }
}