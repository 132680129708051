import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import throttle from 'lodash.throttle';

import getZipData from '../service.js';

import fingerScrollIcon from '../images/finger-scroll-icon.png';

import LoadingAnimation from '../ui-components/loadingAnimation';

const routeVideos = {
    urban: [
        '/videos/USPS_EDDM_9x16_06-CityRoute1.mp4',
        '/videos/USPS_EDDM_9x16_06-CityRoute2.mp4',
        '/videos/USPS_EDDM_9x16_06-CityRoute3.mp4'
    ],
    suburban: [
        '/videos/USPS_EDDM_9x16_06-SuburbRoute1.mp4',
        '/videos/USPS_EDDM_9x16_06-SuburbRoute2.mp4',
        '/videos/USPS_EDDM_9x16_06-SuburbRoute3.mp4'
    ],
    rural: [
        '/videos/USPS_EDDM_9x16_06-RuralRoute1.mp4',
        '/videos/USPS_EDDM_9x16_06-RuralRoute2.mp4',
        '/videos/USPS_EDDM_9x16_06-RuralRoute3.mp4'
    ]
}

const routePosters = {
    urban: '/images/urban-route-bg.png',
    suburban: '/images/suburban-route-bg.jpg',
    rural: '/images/rural-route-bg.jpg'
};

export default class RouteSelection extends Component {


    constructor(props) {
        super(props);

        // load pre-existing zip data if possible
        let zipData = window.localStorage.getItem('zipData'),
            firstTime = window.localStorage.getItem('firstTime');

        if (zipData) {
            zipData = JSON.parse(zipData);
        } else {
            zipData = { routes: [], zip: null };
        }

        
        if (firstTime === null) {
            firstTime = true;
        } else if (firstTime === 'false') {
            firstTime = false;
        }

        this.state = {
            zipCode: this.props.match.params.zipCode,
            zipData: zipData,
            routes: [],
            selectedRouteCrid: null,
            selectedRouteIndex: 0,
            selectedRouteObject: {},
            selectorPristine: true,
            modalVisible: firstTime,        // flag for hide/show state
            firstTime: firstTime,      // flag for if the user's seen it before
            demographicVideos: []
        };


        this.debounceDuration = 500;

        // native select refs
        this.selectRef = React.createRef();

        // custom selector refs
        this.selectorRef = React.createRef();
        this.optionsContainerRef = React.createRef();
        this.optionRefs = []; // populate this after api fetch 

        this.isScrolling = null;


        this.hideModal = this.hideModal.bind(this);
    }


    componentDidMount() {

        // first attempt to use data from localstorage
        // make sure zip matches
        if (this.state.zipData && 
            this.state.zipData.zip === this.state.zipCode) {

            console.log('Route Data found in Local Storage');

            this.processRoutes(this.state.zipData);


        } else {  
            // this should only ever occur if a zip code is updated from the URL bar
            // otherwise, the zip should already be in localStorage
            console.log('ZIP Code updated via URL.');

            // get route data from API, store in localStorage
            getZipData(this.state.zipCode).then(result => {

                if (result.routes.length === 0) {
                    // invalid zip code: send back to ZIP input
                    this.setState({ redirect: true });

                    return;
                }

                window.localStorage.setItem('zipData', JSON.stringify(result));

                this.processRoutes(result);

            }).catch(e => {

                // at this point, there are no routes in local storage, and routes could not be retrieved from API, 
                // send back to ZIP input
                this.setState({ redirect: true });
            });
        }
    }



    /*
        prepare state and dom to display route UI
    */
    processRoutes(routeData) {

        // add a video index to each route
        let currentVideoIndex = 0;

        routeData.routes.forEach((route) => {
            route.videoIndex = currentVideoIndex;

            if (currentVideoIndex < 2) {
                currentVideoIndex++;
            } else {
                currentVideoIndex = 0;
            }
        });


        this.optionRefs = routeData.routes.map((route, index) => React.createRef());

        // select the video set for the demographic of the selected zip code
        this.setDemographicVideos(routeData.demographicCode);

        this.setState({ 
            routes: routeData.routes,
            selectedRouteCrid: routeData.routes[0].ZIP_CRID,
            selectedRouteObject: routeData.routes[0]
        }, () => {

            // save the default selected route to localstorage
            window.localStorage.setItem('selectedRoute', JSON.stringify(routeData.routes[0]));
            
            // play the default video 
            this.playRouteVideo(this.state.demographicVideos[0]);
        });
    }

    setDemographicVideos(demographicCode) {

        let demographicVideos;

        switch (demographicCode) {
            case 'U':
                demographicVideos = routeVideos.urban;
                this.props.setPoster(routePosters.urban);
                break;
            case 'S': 
                demographicVideos = routeVideos.suburban;
                this.props.setPoster(routePosters.suburban);
                break;
            case 'R':
                demographicVideos = routeVideos.rural;
                this.props.setPoster(routePosters.rural);
                break;
            default:
                demographicVideos = routeVideos.suburban;
                this.props.setPoster(routePosters.suburban);
        }

        this.setState({ demographicVideos: demographicVideos });
    }


    playRouteVideo(videoIndex) {

        const videoUrl = this.state.demographicVideos[this.state.selectedRouteObject.videoIndex];

        this.props.triggerVideo(videoUrl, {
            loop: false,
            onPlay: () => {
                // console.log('playing video ' + videoUrl);
            }
        }).then(result => {

        }).catch(e => {

        });
    }

    getRouteObject(zipCrid) {

        let matchedRoute = false;

        this.state.routes.forEach(route => {
            if (route.ZIP_CRID === zipCrid) {
                matchedRoute = route;
            }
        });

        return matchedRoute;
    }



    // utilities
    getScrollPosition(innerElement, outerElement) {
        var elementTop = innerElement.offsetTop;
        var divTop = outerElement.offsetTop;
        var elementRelativeTop = elementTop - divTop;

        return elementRelativeTop;
    }



    // Custom select stuff

    renderCustomOptions() {
        return this.state.routes.map((route, index) => {

            const defaultRoute = this.state.selectorPristine && index === 0,
                routeIsSelected = route.ZIP_CRID === this.state.selectedRouteCrid || defaultRoute;

            return (
                <li 
                    className={'custom-selector-option ' + (routeIsSelected ? 'selected' : '')} 
                    route={route.ZIP_CRID}
                    ref={this.optionRefs[index]}
                    index={index}
                    key={index}>

                    {route.ZIP_CODE}-{route.CRID_ID}
                </li>
            );
        });
    }

    /*
        Uses the custom UI to select the option in the vertical center of the list

        TODO: snap to center on scroll end.

    */
    onSelectorScroll(e) {

        window.clearTimeout( this.isScrolling );

        const throttledHandler = throttle(() => { 

            if (this.state.selectorPristine) {
                this.setState({ selectorPristine: false });
            }

            if (this) {

                const selector = this.selectorRef.current,
                    selectorHeight = selector.offsetHeight,
                    centerLine = selectorHeight / 2;

                let selectedZipCrid = null;

                this.optionRefs.forEach(ref => {
                    const option = ref.current;

                    const max = option.offsetTop - e.target.scrollTop + 50,
                        min = option.offsetTop - e.target.scrollTop,
                        centerLineOffsetTop = selector.offsetTop + centerLine;

                    if (centerLineOffsetTop <= max && centerLineOffsetTop >= min) {

                        selectedZipCrid = option.attributes.route.nodeValue;
                    }
                });

                if (selectedZipCrid) {
                    // update the native select element
                    this.selectRef.current.value = selectedZipCrid;

                    const routeObject = this.getRouteObject(selectedZipCrid);

                    // update component state
                    this.selectRoute(selectedZipCrid, routeObject);

                    this.isScrolling = setTimeout(() => {
                        this.onSelectorScrollEnd(routeObject, e.target);
                    }, this.debounceDuration);
                }

            }

        }, this.debounceDuration);

        throttledHandler(this);
    }

    selectRoute(crid, route) {

        this.setState({ 
            selectedRouteCrid: crid, 
            selectedRouteObject: route
        });
    }

    onSelectorScrollEnd(routeObject, element) {

        element.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
        });

        window.localStorage.setItem('selectedRoute', JSON.stringify(routeObject));

        this.playRouteVideo(this.state.demographicVideos[routeObject.videoIndex]);
    }



    // Native select stuff

    setNativeSelectValue(value) {
        this.selectRef.current.value = value;
        this.setState({ selectedRouteCrid: value });
    }
    renderNativeOptions() {

        return this.state.zipData.routes.map((route, index) => {

            if (!route) return null;

            return <option value={route} key={index}>Route: {route.ZIP_CRID}</option>
        });
    }


    hideModal() {

        window.localStorage.setItem('firstTime', false);
        
        this.setState({
            modalVisible: false,
            firstTime: false
        });
    }




    renderInstructionalTextModal() {

        if (this.state.modalVisible && this.state.firstTime) {

            return (
                <div className="instructional-text-modal">

                    <div className="close-button" onClick={this.hideModal}/>

                    <h3>How It Works</h3>

                    <div className="separator" />

                    <div className="column">
                        <img className="finger-scroll-icon" src={fingerScrollIcon} alt="Finger pointing and dragging to scroll through routes." />

                        <p>Scroll through the<br/>routes for route<br/>demographics</p>
                    </div>

                    <div className="button" onClick={this.hideModal}>
                        <p>Got It</p>
                    </div>
                </div>
            );
        }
    }

    numberWithCommas(x) {

        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return null;
        }
    }

    renderRouteData() {

        if (this.state.routes.length > 0) {

            const routeDetails = {
                residences: this.state.selectedRouteObject.RES_CNT,
                businesses: this.state.selectedRouteObject.BUS_CNT,
                type: this.state.selectedRouteObject.TYPE,
                medianIncome: this.numberWithCommas(this.state.selectedRouteObject.MED_INCOME),
                size: Number.parseFloat(this.state.selectedRouteObject.AVG_HH_SIZ).toFixed(1)
            };
            
            return(
                <Fragment>
                    <div className="column-1">
                        <h2>Routes</h2>

                        <select className="route-select" ref={this.selectRef}>
                            {this.renderNativeOptions()}
                        </select>

                        <div 
                            className="custom-selector-container" 
                            onScroll={e => this.onSelectorScroll(e) }
                            ref={this.selectorRef}>

                            <ul className="custom-selector-options-container" ref={this.optionsContainerRef}>
                                { this.optionRefs.length > 0 ? this.renderCustomOptions() : null }
                            </ul>
                        </div>
                    </div>

                    <div className="column-2">
                        <h2>Demographics</h2>

                        <div className="route-selector-results">
                            <p>Residences: <strong>{routeDetails.residences}</strong></p>
                            <p>Businesses: <strong>{routeDetails.businesses}</strong></p>
                            <p>Size: <strong>{routeDetails.size} Ppl.</strong></p>
                            <p>Median Age: <strong>{this.state.selectedRouteObject.MED_AGE}</strong></p>
                            <p>Income: <strong>${routeDetails.medianIncome}</strong></p>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return <LoadingAnimation />;
        }
    }

    render() {

        if (this.state.redirect) return <Redirect to={{ pathname: '/intro' }} />

        return (
            <div className="route route--route-selection">
                <h1>Postal Routes near <strong>{this.state.zipCode}.</strong></h1>

                <div className="route-selector-container">
                    {this.renderRouteData()}
                </div>

                { this.renderInstructionalTextModal() }

                <div className="buttons-container">
    


                    { !this.state.modalVisible ?      
                        <div className="buttons">

                            <Link to="/ending" className="button">Select Route</Link>

                            <Link to="/intro" className="button">Try Another ZIP Code™</Link>
                        </div>
                    : null }
                </div>
                <div className="gradient-container">
                    <p className="legal">Depiction of ZIP<sup>®</sup> Code and mail routes are representational only.</p>
                </div>
            </div>
        );
    }
}