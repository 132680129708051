
const url = 'https://www.geteddm.com/api/public/eddm/routes/zipcode/';    // prod
// const url = 'http://stage.eddm-retail.mrmclient.com/api/public/eddm/routes/zipcode/';    // stage


function getZipData (zip) {

    return new Promise((resolve, reject) => {

        fetch(url + zip)
            .then(res => res.json())
            .then(result => {

                const routes = result.routes.map(route => { return route.attributes });

                const data = {    
                    zip: zip,
                    demographicCode: result.demographic_code,
                    routes: routes
                };

                resolve(data);
            }, error => {

            });
    });
}

export default getZipData;