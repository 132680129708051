import React, { Component } from 'react';

const testCanvasStyle = {
  display: 'none'
};


class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoStarted: false,
      source: props.source,
      poster: props.poster,
      playing: false
    };

    this.canvasRef = React.createRef();
    this.testCanvasRef = React.createRef();
    this.videoRef = React.createRef();
    this.imageRef = React.createRef();

    this.canvasContext = null;
    this.testCanvasContext = null;

    this.createCanvasMirror = this.createCanvasMirror.bind(this);
    this.drawToCanvas = this.drawToCanvas.bind(this);
    this.loadAndPlayVideo = this.loadAndPlayVideo.bind(this);
  }


  /*
    Load video and start canvas mirroring
  */
  componentDidMount() {    

    if (this.state.playing === false) {
      this.videoRef.current.load();
    }
    
    // only render it if video is not already playing
    if (this.state.poster) {
      this.renderPosterImage();
    } 

    if (this.state.source) {

      this.canvasContext = this.canvasRef.current.getContext('2d');
      this.cw = parseInt(this.props.width);
      this.ch = parseInt(this.props.height);

      this.testCanvasContext = this.testCanvasRef.current.getContext('2d');

      this.createCanvasMirror();
    }
  }

  /*
    When the section is changed update the sources of video
  */
  componentDidUpdate(prevProps) {


    // Video Source Update
    if (prevProps.source !== this.props.source &&
      this.props.source !== this.state.source) {

      this.setState({ source: this.props.source }, () => {

        if (this.props.play === true) {  // play video if source changes
          this.loadAndPlayVideo();
        }
      });
    } else if (prevProps.play !== this.props.play && this.props.play === true) { // play video if triggered directly only if source has not changed

      this.loadAndPlayVideo();
    }

    // Poster Image Update
    if (this.props.poster !== this.state.poster) {

      this.setState({ poster: this.props.poster }, () => {
          this.renderPosterImage(); 
      });
    }


  }


  componentWillUnmount() {
    clearTimeout(this.drawTimeout);
  }


  loadAndPlayVideo(callback) {

    this.videoRef.current.pause();

    let component = this;

    // starts rendering video to canvas
    const startPlaying = (callback) => {
      this.setState({ playing: true, videoStarted: true, videoFallback: false }, () => {

        this.drawToCanvas();

        if (callback) {

          callback();
        }
      });
    }

    // console.log(this.videoRef.current);
    this.videoRef.current.load();
    this.videoRef.current.currentTime = 0;
    var playPromise = this.videoRef.current.play();
    // console.log('Play promise: ', playPromise);

    // In browsers that don’t yet support this functionality,
    // playPromise won’t be defined.
    if (playPromise !== undefined) {

      playPromise.then((details) => {
        // Automatic playback started!

        // console.log(details);
        // console.log('video playing')

        startPlaying(() => this.props.onVideoPlay());

      }).catch((error) => {
        console.log(error);
        this.props.onVideoFail(error);
        component.setState({ videoFallback: true, videoStarted: false });
      });
    }


  }


  createCanvasMirror() {
    this.canvasRef.current.width = this.cw;
    this.canvasRef.current.height = this.ch;

    this.videoRef.current.addEventListener('ended', () => {

      if (this.props.loop) {

      } else {
        this.props.onVideoEnd();

        this.setState({ playing: false });
      }
    });
  }

  /*
    Render the poster image to the img element and use that to render the first canvas frame

    This seems redundant but it wont render the first frame of video normally.
  */
  renderPosterImage() {

    // console.log('renderPosterImage', this.state.poster);

    if (this.videoRef.current.paused) {

      // console.log('video is paused');

      const image = new Image(), //this.imageRef.current,
        context = this.canvasRef.current.getContext('2d');

      // console.log('image', image);
      // console.log('context', context);

      image.addEventListener('load', () => {
        // console.log('draw poster image to canvas');
        context.drawImage(image, 0, 0, this.cw, this.ch);
      });

      image.src = this.state.poster;

    }
  }

  drawToCanvas(singleFrameOveride) {

    clearTimeout(this.drawTimeout);

    this.drawTimeout = setTimeout(() => {

      let context = this.canvasRef.current.getContext('2d'); // for some godforsaken reason this.context comes up as an empty object here so im just gonna grab it every time woohoooo

      if ((this.state.playing && !this.isBackgroundBlack() && context.drawImage) ||
        singleFrameOveride) {

        context.drawImage(this.videoRef.current, 0, 0, this.cw, this.ch);

        this.drawToCanvas();
      }
    }, 40);
  }

  /*
    Use a hidden canvas to render the frame and check if the background color is black!

    If it is, we know to NOT render the frame.  This is a fix specifically for IE-11
    https://jira.mrmanywhere.com/browse/USPSLP-369
  */

  isBackgroundBlack() {

    if (this.testCanvasContext) {

      this.testCanvasContext.drawImage(this.videoRef.current, 0, 0, this.cw, this.ch);

      const data = this.testCanvasContext.getImageData(this.cw/4, this.ch/4, 20, 20).data;

      for (let i=0; i < data.length; i+=4) {

        if (data[i] !== 0) {
          return false;
        }
      }

      return true;
    }
  }


  /*
    Need to clear the canvas when the section is changing
  */
  clearCanvas(context, cw, ch) {

    if (context) {
      context.clearRect(0, 0, cw, ch);
    }
  }


  render() {


    const videoStyle = {
      backgroundImage: 'url(' + this.state.poster + ')',
      backgroundSize: this.props.width + 'px ' + this.props.height + 'px',
    };


    return (

      <div className={'video-container ' + this.props.className + (this.state.videoFallback ? ' video-fallback' : '')}>

        <div className={'canvas-container blue ' + (this.state.videoStarted ? 'playing' : 'not-playing')}>
          
          <canvas 
            id="canvas" 
            ref={this.canvasRef} 
            width={this.props.width} 
            height={this.props.height} />
          
          <canvas 
            id="test-canvas" 
            ref={this.testCanvasRef} 
            width={this.props.width} 
            height={this.props.height} 
            style={testCanvasStyle}/>

          <img 
            ref={this.imageRef}
            className="poster-image" 
            width={this.props.width} 
            height={this.props.height} 
            alt="Fallback for video" />

        </div>

        <video
          id="player"
          ref={this.videoRef}
          width={this.props.width}
          height={this.props.height}
          playsInline={true}
          src={this.state.source}
          loop={this.props.loop}
          style={videoStyle}
          preload="auto"
          crossOrigin="anonymous"
          muted>
          </video>
      </div>
    )
  }
}






export default Video
