
/*
    Fades the lines of a paragraph in individually.

    - accepts html BUT ONLY around individual words
    - triggers automatically on render, so just dont render it until you want it to start
    - wrap this component with the text element in use, e.g. <p><AnimatedWords /></p>

    Props:
        - words: the full string of the sentence/paragraph/whatever
        - interval (optional): time between word animations, defaults to 200
*/



import React, { Component } from 'react';
import DOMPurify from 'dompurify';

export default class AnimatedWords extends Component {

    constructor(props) {
        super(props);


        const lines = this.props.lines.map(line => {
            return line + ' ';
        });

        
        this.state = {
            currentLineIndex: 0,
            lines: lines,
            interval: props.interval || 200,
        };


        this.showStyles = { opacity: 1, transition: 'opacity ' + this.state.interval + 'ms' };

        // set up default styles
        this.lineStyles = lines.map(line => {
            return { opacity: 0, transition: 'opacity ' + this.state.interval + 'ms'};
        });

        this.lineRefs = this.state.lines.map(line => {
            return React.createRef();
        });
    }

    componentDidMount() {

        // return;
        this.interval = setInterval(() => {

            this.lineStyles[this.state.currentLineIndex] = this.showStyles;

            const newLineIndex = this.state.currentLineIndex + 1;

            this.setState({ currentLineIndex: newLineIndex });

        }, this.state.interval);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <span className="animated-words-container">
                {this.state.lines.map((word, index) => {
                    var clean = DOMPurify.sanitize(word);
                    // console.log(this.lineStyles[index]);

                    return (
                        <span 
                            className="animated-word" 
                            ref={this.lineRefs[index]} 
                            style={this.lineStyles[index]} 
                            dangerouslySetInnerHTML={{__html: clean }} 
                            key={index} />
                    );
                })}
            </span>
        );
    }
}