import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import PeriodSeparator from '../ui-components/periodSeparator.js';

import uspsLogo from '../images/usps-logo.png';


const endingVideos = {
        urban: '/videos/USPS_EDDM_9x16_07-EndCITY.mp4',
        suburban: '/videos/USPS_EDDM_9x16_07-EndSUBURB.mp4',
        rural: '/videos/USPS_EDDM_9x16_07-EndRURAL.mp4'
    },
    posterImages = {
        urbanStart: '/images/urban-outro-start.jpg',
        urbanEnd: '/images/urban-outro-end.jpg',
        suburbanStart: '/images/suburban-outro-start.jpg',
        suburbanEnd: '/images/suburban-outro-end.jpg',
        ruralStart: '/images/rural-outro-start.jpg',
        ruralEnd: '/images/rural-outro-end.jpg'
    };

export default class Ending extends Component {


    constructor(props) {
        super(props);

        const routeData = window.localStorage.getItem('selectedRoute'),
            zipData = window.localStorage.getItem('zipData');

        let demographic;

        if (zipData && routeData) {

            switch (JSON.parse(zipData).demographicCode) {
                case 'U':
                    demographic = 'urban';
                    break;
                case 'R':
                    demographic = 'rural';
                    break;
                case 'S':
                default: 
                    demographic = 'suburban';
                    break;
            }

            this.state = {
                route: JSON.parse(routeData),
                zipData: JSON.parse(zipData),
                demographic: demographic
            };
        } else {
            this.state = {
                redirect: true
            }
        }

    }

    componentDidMount() {
        this.props.setPoster(posterImages[this.state.demographic + 'Start']);
        this.props.triggerVideo(endingVideos[this.state.demographic], {
            loop: false,
            onPlay: () => {
                this.props.setPoster(posterImages[this.state.demographic + 'End']);
            }
        }).then(result => {

        }).catch(e => {
            console.error(e);
        });
    }


    numberWithCommas(x) {

        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return null;
        }
    }


    render() {

        if (this.state.redirect) return <Redirect to={{ pathname: '/intro' }} />

        return (

            <div className="route route--ending">

                <Link to="/">
                    <img className="logo" src={uspsLogo} alt="USPS Logo" />
                </Link>

                <h1>Bring your store to homes in <br/>this postal route with EDDM<sup>®</sup>.</h1>

                <div className="route-data">

                    <h2>Route</h2>

                    <p className="route-id">{this.state.route.ZIP_CODE}-{this.state.route.CRID_ID}</p>

                    <p className="period-separator-row">
                        <span className="left">Residences</span>
                        <PeriodSeparator />
                        <span className="right">
                            <strong>{this.state.route.RES_CNT}</strong>
                        </span>
                    </p>
                    <p className="period-separator-row">
                        <span className="left">Businesses</span>
                        <PeriodSeparator />
                        <span className="right">
                            <strong>{this.state.route.BUS_CNT}</strong>
                        </span>
                    </p>
                    <p className="period-separator-row">
                        <span className="left">Size</span>
                        <PeriodSeparator />
                        <span className="right">
                            <strong>{ Number.parseFloat(this.state.route.AVG_HH_SIZ).toFixed(1) } Ppl</strong>
                        </span>
                    </p>
                    <p className="period-separator-row">
                        <span className="left">Median Age</span>
                        <PeriodSeparator />
                        <span className="right">
                            <strong>{ this.state.route.MED_AGE }</strong>
                        </span>
                    </p>
                    <p className="period-separator-row">
                        <span className="left">Median Income</span>
                        <PeriodSeparator />
                        <span className="right">
                            <strong>${ this.numberWithCommas(this.state.route.MED_INCOME) }</strong>
                        </span>
                    </p>
                </div>

                <div className="buttons-container">
                    <div className="buttons">
                        <a 
                            href="https://www.uspsdelivers.com/contact_details/bring-your-store-to-them-with-our-free-eddm-advertising-kit/?iom=G107-12RT-BSED-DM-XXX-XX-XXX-XX-XX-X-EDD&utm_medium=directmail&utm_source=digitalext&utm_campaign=eddmretail&utm_content=g107_12rt" 
                            className="button">
                            Get Started
                        </a>
                    </div>
                </div>
                

            </div>
        );
    }
}