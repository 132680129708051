import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from 'react-router-last-location';



// third party
import Normalize from 'react-normalize';


// custom components
import ScrollToTop from './utility-components/scrollToTop';
import Video from './ui-components/video';


// routes
import Home from './routes/home';
import Intro from './routes/intro';
import RouteSelection from './routes/routeSelection';
import Ending from './routes/ending';

import './App.scss';

const transparentPoster = '/images/transparent.png',
  desktopWidthMax = 500,
  desktopHeightMax = 889;



export default class App extends Component {

  constructor(props) {
    super(props);

    const videoSize = this.calculateVideoSize();

    this.state = {
      route: {},
      play: false,
      videoUrl: '/videos/USPS_EDDM_9x16_00-FullIntro.mp4',
      poster: '', ///images/home-video-bg.jpg',
      posterUrl: transparentPoster,
      width: videoSize.width,
      height: videoSize.height
    }

    this.appRef = React.createRef();

    this.triggerVideo = this.triggerVideo.bind(this);
    this.setPoster = this.setPoster.bind(this);
  }

  whichIsSmaller(a, b) {
    if (a < b) {
      return a;
    } else {
      return b;
    }
  }

  calculateVideoSize() {

    const ratio = 1920 / 1080;

    // default sizing is for mobile
    let screenHeight = document.body.offsetHeight,
      screenWidth = document.body.offsetWidth;


    // override for desktop
    if (document.body.offsetWidth > desktopWidthMax) {
      screenHeight = this.whichIsSmaller(desktopHeightMax, screenHeight);
      screenWidth = desktopWidthMax
    }

    // by default set the video height to be screen height
    let width = screenHeight / ratio,
      height = screenHeight;

    // if the video is too narrow at screen height, increase size of video to fill screen
    if (width < screenWidth) {
      width = screenWidth;
      height = screenWidth * ratio;
    }
    
    return {
      height: height,
      width: width
    }
  }

  setPoster(url) {
    this.setState({ poster: url });
  }


  triggerVideo(url, options) {

    return new Promise((resolve, reject) => {

      let loop = false,
        play = true;
      
      if (options.loop) {
        loop = true;
      }

      if (options.play === false) {
        play = false;
      }

      this.setState({ 
        videoUrl: url,
        loop: loop,
        play: play,  // a flag to trigger play in the Video component
        onVideoPlay: () => {

          // run the onPlay callback provided by route component
          if (options.onPlay) {
            options.onPlay();
          }
        },
        onVideoEnd: (e) => {

          this.setState({ play: false });

          resolve();
        },
        onVideoFail: (e) => {
          this.setState({ play: false });

          reject(e);
        } 
      });

    });
  }

  render() {


    return (
      <div className="App" ref={this.appRef}>
        <Normalize />

        <Video
          className="background-video-player"
          width={this.state.width + 'px'}
          height={this.state.height + 'px'}
          poster={this.state.poster}
          source={this.state.videoUrl}
          loop={this.state.loop}
          onVideoPlay={this.state.onVideoPlay}
          onVideoEnd={this.state.onVideoEnd}
          onVideoFail={this.state.onVideoFail} 
          play={this.state.play} />

        <Router>

          <LastLocationProvider>

            <ScrollToTop />

            <Switch>
              <Route 
                exact 
                path="/" 
                render={ props => (
                  <Home triggerVideo={this.triggerVideo} setPoster={this.setPoster} playing={this.state.play} />
                )} />

              <Route 
                exact 
                path="/intro" 
                render={ props => (
                  <Intro triggerVideo={this.triggerVideo} setPoster={this.setPoster} playing={this.state.play}  />
                )} />

              <Route 
                exact 
                path="/route-selection/:zipCode" 
                render={ props => (
                  <RouteSelection triggerVideo={this.triggerVideo} setPoster={this.setPoster} playing={this.state.play} {...props} />
                )} />

              <Route 
                exact 
                path="/ending" 
                render={ props => (
                  <Ending triggerVideo={this.triggerVideo} setPoster={this.setPoster} playing={this.state.play} />
                )} />

            </Switch>
          </LastLocationProvider>

        </Router>

      </div>
    );
  }
}
