function PeriodSeparator(props) {


    return (
        <span className="period-separator">
            <span className="period-separator-inner">
                ....................................................................................................................................................................
            </span>
        </span>
    );
}

export default PeriodSeparator;